import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import Modal from "../Modal";
import { NetworkConnectOptions } from "../WalletModal/Components/NetworkConnectOptions";

var NetworkListModal = _ref => {
  var chainId = _ref.chainId,
      onSelect = _ref.onSelect,
      isOpen = _ref.isOpen,
      toggle = _ref.toggle;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(Modal, {
    isOpen: isOpen,
    onDismiss: toggle,
    title: t('switch_network'),
    contentClassName: "switchNetworkContent",
    headerClassName: "switchNetworkHeader"
  }, __jsx(NetworkConnectOptions, {
    chainId: chainId,
    onSelect: onSelect
  }));
};

export default NetworkListModal;
import _taggedTemplateLiteral from "/vercel/path0/node_modules/.pnpm/next@12.2.0_@babel+core@7.16.0_react-dom@17.0.2_react@17.0.2/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3;

import styled, { css } from 'styled-components';
import { ReactComponent as MenuIcon } from "../../assets/images/menu.svg";
import { MainNavItem } from "./components/MainNavItem";
export var StyledTokenlonHeader = styled.div.withConfig({
  displayName: "StyledTokenlonHeader",
  componentId: "sc-m51x1u-0"
})(["background:", ";border-bottom:", ";z-index:100;@media screen and (max-width:640px){box-shadow:none;}"], _ref => {
  var displayThemeColor = _ref.displayThemeColor,
      theme = _ref.theme;
  return displayThemeColor ? theme.colors.bgColor : theme.colors.headerNormal;
}, _ref2 => {
  var displayThemeColor = _ref2.displayThemeColor;
  return displayThemeColor ? 0 : '0.5px solid rgb(234, 236, 246)';
});
export var StyledTabsContainer = styled.ul.withConfig({
  displayName: "StyledTabsContainer",
  componentId: "sc-m51x1u-1"
})(["list-style:none;display:flex;align-items:center;margin:0;padding-left:50px;margin-left:-24px;"]);
export var StyledHeaderRight = styled.div.withConfig({
  displayName: "StyledHeaderRight",
  componentId: "sc-m51x1u-2"
})(["margin-left:auto;display:flex;flex-flow:row nowrap;align-items:center;justify-content:flex-end;@media screen and (max-width:640px){margin-left:auto;display:flex;flex-direction:row;align-items:center;}"]);
export var StyledTokenlonHeaderProfile = styled.div.withConfig({
  displayName: "StyledTokenlonHeaderProfile",
  componentId: "sc-m51x1u-3"
})(["cursor:pointer;.connect-wallet-btn{color:#5f4dbc;font-size:12px;background-color:#fff;border:1px solid #5f4dbc;padding:6px 14px;border-radius:10px;outline:none;width:80px;cursor:pointer;}.wallet-address-btn{font-weight:500;text-align:center;font-size:11px;color:#5f4dbc;border:1px solid #e2e5f1;padding:6px 14px;border-radius:10px;outline:none;background-color:#f5f7fb;cursor:pointer;}"]);
export var StyledLonSubNav = styled.div.withConfig({
  displayName: "StyledLonSubNav",
  componentId: "sc-m51x1u-4"
})(["position:absolute;width:100vw;top:84px;z-index:1;background-color:#fff;.lon-subNav-container{padding-left:142px !important;}.sub-nav-wrapper{width:1200px;padding-left:0;margin:0 auto;margin-left:590px;}"]);
export var StyledLogo = styled.img.withConfig({
  displayName: "StyledLogo",
  componentId: "sc-m51x1u-5"
})(["width:170px;height:32.63px;", ""], _ref3 => {
  var theme = _ref3.theme;
  return theme.media.sm(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    width: 114.62px;\n    height: 22px;\n  "])));
});
export var StyledMenuIcon = styled(MenuIcon).attrs({
  'data-testid': 'header-menu-icon'
}).withConfig({
  displayName: "StyledMenuIcon",
  componentId: "sc-m51x1u-6"
})(["display:none;cursor:pointer;", ""], _ref4 => {
  var theme = _ref4.theme;
  return theme.media.lg(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    display: inline-block !important;\n    width: 40px;\n    height: 40px;\n    margin-left: 7px;   \n  "])));
});
export var StyledSubNavItem = styled(MainNavItem).withConfig({
  displayName: "StyledSubNavItem",
  componentId: "sc-m51x1u-7"
})(["margin-right:22px;margin-left:22px;padding:4px 0;color:#9598ab;font-size:16px;cursor:pointer;display:flex;align-items:center;justify-content:center;height:32px;&:hover{color:#191c1e;}", ""], _ref5 => {
  var active = _ref5.active;
  return active && css(["background:rgba(95,85,169,0.2);border-radius:8px;color:#5f55a9;text-align:center;font-weight:bold;a,span{display:inline-block;padding:0 15px;line-height:22px;}&:first-of-type{margin-left:0;}"]);
});
export var StyledMainNavItem = styled(MainNavItem).withConfig({
  displayName: "StyledMainNavItem",
  componentId: "sc-m51x1u-8"
})(["margin-right:27px;color:#333333;font-size:16px;white-space:nowrap;text-decoration:none;cursor:pointer;&:hover{background:#eaecf6;border-radius:40px;}a,span{display:inline-block;padding:8px 24px;user-select:none;}"]);
export var StyledControls = styled.div.withConfig({
  displayName: "StyledControls",
  componentId: "sc-m51x1u-9"
})(["margin-left:auto;display:flex;flex-direction:row;justify-content:center;align-items:center;"]);
export var ConnectButton = styled.div.withConfig({
  displayName: "ConnectButton",
  componentId: "sc-m51x1u-10"
})(["cursor:pointer;box-sizing:border-box;text-align:center;font-weight:500;font-size:14px;line-height:20px;color:#fff;border-radius:28px;border:1px solid #eff0fa;padding:10px 20px;position:relative;background:", ";white-space:nowrap;"], _ref6 => {
  var theme = _ref6.theme;
  return theme.colors.btnPrimary;
});
export var StyledInstantButton = styled.a.withConfig({
  displayName: "StyledInstantButton",
  componentId: "sc-m51x1u-11"
})(["cursor:pointer;box-sizing:border-box;text-align:center;font-weight:500;font-size:14px;line-height:20px;color:#fff !important;border-radius:28px;border:1px solid #eff0fa;padding:10px 20px;position:relative;background-color:#5f4dbc;white-space:nowrap;"]);
export var StyledHeaderMain = styled.div.withConfig({
  displayName: "StyledHeaderMain",
  componentId: "sc-m51x1u-12"
})(["position:relative;max-width:1200px;margin:0 auto;margin-top:-1px;height:84px;box-sizing:border-box;display:flex;align-items:center;justify-content:flex-start;", ""], _ref7 => {
  var theme = _ref7.theme;
  return theme.media.lg(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    width: 100%;\n    height: 60px;\n    padding: 0 12px;\n    justify-content: space-between;\n  "])));
});
export var StyledSubNavPortal = styled.div.withConfig({
  displayName: "StyledSubNavPortal",
  componentId: "sc-m51x1u-13"
})(["display:flex;margin-top:0;width:1200px;margin:0 auto;list-style:none;padding:12px 0;"]);
export var StyledHoverRow = styled.div.withConfig({
  displayName: "StyledHoverRow",
  componentId: "sc-m51x1u-14"
})(["position:absolute;left:0;right:0;border-bottom:0.5px solid rgb(234,236,246);background:", ";z-index:11;"], _ref8 => {
  var displayThemeColor = _ref8.displayThemeColor,
      theme = _ref8.theme;
  return displayThemeColor ? theme.colors.bgColor : theme.colors.headerNormal;
});
export var StyledSubNavPortalContainer = styled.div.withConfig({
  displayName: "StyledSubNavPortalContainer",
  componentId: "sc-m51x1u-15"
})(["border-bottom:0.5px solid rgb(234,236,246);background-color:#fff;z-index:1;&:after{content:'';clear:both;}"]);
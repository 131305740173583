import React from "react";
var __jsx = React.createElement;
import styled from 'styled-components';
var StyledArrowIcon = styled.div.withConfig({
  displayName: "StyledArrowIcon",
  componentId: "sc-o3ykyr-0"
})(["display:flex;justify-content:center;align-items:center;margin-left:5px;color:#9598ab;"]);

var ArrowIcon = _ref => {
  var color = _ref.color;
  return __jsx(StyledArrowIcon, {
    style: {
      color: color
    }
  }, __jsx("svg", {
    width: "7",
    height: "5",
    viewBox: "0 0 7 5",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("path", {
    d: "M3.62902 4.75694C3.44887 4.98326 3.10504 4.98326 2.92489 4.75694L0.258277 1.40712C0.0235906 1.1123 0.233524 0.676853 0.610346 0.676853L5.94356 0.676853C6.32038 0.676853 6.53032 1.1123 6.29563 1.40712L3.62902 4.75694Z",
    fill: "currentColor"
  })));
};

export default ArrowIcon;